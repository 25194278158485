// Migrated
<template>
  <div>
    <ResponsiveText class="box-title mb-4">
      <span>
        {{ $t('charterFilterCharterTags') }}
      </span>
    </ResponsiveText>
    <div class="mb-6 md:pb-2 box-caption">
      <div class="inline mr-2">
        –
      </div>
      <div class="inline font-normal">
        {{ $t('solresorStartPage.selectedCaption') }}
      </div>
    </div>
    <p class="mb-6">
      {{ $t('solresorStartPage.selectedText') }}
    </p>
    <SlidesBehindSlider>
      <CharterSimpleBox
        v-for="(campaign, idx) in selectedCampaigns"
        :key="idx"
        class="my-6"
        :text="campaign.text"
        :image="campaign.image"
        :url="campaign.url"
      >
        <template #top-left>
          <CampaignTag
            class="flex"
            :tag="{ title: campaign.text, icon: campaign.icon || defaultTagIcon }"
            size="lg"
            :tag-style="campaign.style"
            show-icon
          />
        </template>
      </CharterSimpleBox>
    </SlidesBehindSlider>
  </div>
</template>

<script>
import { mapState } from 'pinia'

export default defineNuxtComponent({
  setup () {
    const { urls: localeURLs } = useLocale()

    return {
      localeURLs,
    }
  },

  data () {
    return {
      defaultTagIcon: 'campaign/solresor-icon-empty.png',
    }
  },

  computed: {
    ...mapState(useCharterStore, {
      campaigns: 'tagsFeatured',
    }),

    selectedCampaigns () {
      // Hardcode grouptravel selected cause it's a completely different thing in the system
      const grouptravelSelectedSlide = {
        text: this.$t('solresorGroupTravel.title'),
        image: this.$t('solresorGroupTravel.image'),
        url: this.localeURLs['campaign-gruppresor'],
      }

      return [
        ...this.campaigns.map(campaign => ({
          ...campaign,
          text: campaign.title,
          image: campaign.hero_image,
          url: `${this.localeURLs.campaign}/${campaign.url_name}`,
        })),
        grouptravelSelectedSlide,
      ]
    },
  },
})
</script>
